import './badExchangerDescr.css'

function BadExchangerDescr (){
    return(
        <div className="badDescription__inner">
            <p className="badDescription__item"><span>Черный список</span> - это список неблагоприятных обменников криптовалюты, который составлялся  экспертами компании Криптоевразия. Мы не можем гарантировать вам сохранность ваших средств, при использовании обменников криптовалюты из данного списка.</p>
        </div>
    )
}

export {BadExchangerDescr};