import React from "react";
import Slider from "react-slick";

import './../../component/slider/exchangerSlider.css'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Profile from './../../../../image/profile.svg'

const BadExchengerSlider = () => {
    const sliderData = [
        {
            id: '1',
            title: 'Slex',
            name: 'Anon',
            estimation: '3 / 10',
            reviews: 'Обменник задержал мой перевод на несколько дней, и я так и не получил свои деньги.'
        },
        {
            id: '2',
            title: 'ProBit Global',
            name: 'Anon',
            estimation: '5 / 10',
            reviews: 'Сервис оказался мошенническим, деньги просто исчезли после обмена.'
        },
        {
            id: '3',
            title: 'Bitrue',
            name: 'Anon',
            estimation: '2 / 10',
            reviews: 'Очень высокие комиссии, не рекомендую использовать этот обменник.'
        },
        {
            id: '4',
            title: 'LATOKEN',
            name: 'Anon',
            estimation: '1 / 10',
            reviews: 'Поддержка клиентов не отвечает на запросы, ужасный сервис.'
        },
        {
            id: '5',
            title: 'Slex',
            name: 'Anon',
            estimation: '3 / 10',
            reviews: 'Обменял криптовалюту, но получил меньше, чем ожидал. Непонятные скрытые сборы.'
        },
        {
            id: '6',
            title: 'ProBit Global',
            name: 'Anon',
            estimation: '4 / 10',
            reviews: 'Интерфейс сайта крайне неудобный, сложно разобраться.'
        },
        {
            id: '7',
            title: 'Slex',
            name: 'Anon',
            estimation: '2 / 10',
            reviews: 'Не смог завершить обмен из-за постоянных ошибок на сайте.'
        },
        {
            id: '8',
            title: 'Slex',
            name: 'Anon',
            estimation: '3 / 10',
            reviews: 'Обменник заблокировал мой аккаунт без объяснений, просто ужас!'
        },
        {
            id: '9',
            title: 'Ourbit',
            name: 'Anon',
            estimation: '1 / 10',
            reviews: 'Слишком долго обрабатывают заявки, время – деньги!'
        },
        {
            id: '10',
            title: 'Coinbase Exchange',
            name: 'Anon',
            estimation: '1 /10',
            reviews: 'Курс обмена значительно отличается от заявленного, не доверяйте им.'
        },
        {
            id: '11',
            title: 'LATOKEN',
            name: 'Anon',
            estimation: '1 / 10',
            reviews: 'Сайт постоянно падает, невозможно сделать обмен.'
        },
        {
            id: '12',
            title: 'Slex',
            name: 'Anon',
            estimation: '2 / 10',
            reviews: 'После обмена деньги пришли на другой кошелек, теперь не знаю, как их вернуть.'
        },
        {
            id: '13',
            title: 'ProBit Global',
            name: 'Anon',
            estimation: '4 / 10',
            reviews: 'Отказались вернуть деньги после ошибки с транзакцией'
        },
        {
            id: '14',
            title: 'LATOKEN',
            name: 'Anon',
            estimation: '5 / 10',
            reviews: 'Непонятные условия использования, много подводных камней.'
        },
        {
            id: '15',
            title: 'LATOKEN',
            name: 'Anon',
            estimation: '4 / 10',
            reviews: 'Не самый лучший опыт — были задержки с переводом.'
        },
        {
            id: '16',
            title: 'Slex',
            name: 'Anon',
            estimation: '2 / 10',
            reviews: 'Постоянно возникают технические проблемы, не советую.'
        },
        {
            id: '17',
            title: 'HashKey Global',
            name: 'Anon',
            estimation: '1 / 10',
            reviews: 'Клиентская поддержка отвечает только через несколько дней.'
        },
        // {
        //     id: '18',
        //     title: 'Kraken',
        //     name: 'Anon',
        //     estimation: '10/10',
        //     reviews: ''
        // },
        // {
        //     id: '19',
        //     title: 'OKX',
        //     name: '',
        //     estimation: '10/10',
        //     reviews: ''
        // },
        // {
        //     id: '20',
        //     title: 'Binance',
        //     name: '',
        //     estimation: '10/10',
        //     reviews: ''
        // },
        // {
        //     id: '21',
        //     title: 'Kraken',
        //     name: 'anon',
        //     estimation: '7/10',
        //     reviews: ''
        // },
        // {
        //     id: '22',
        //     title: '',
        //     name: '',
        //     estimation: '7/10',
        //     reviews: ''
        // },
        // {
        //     id: '23',
        //     title: 'Binance',
        //     name: 'Anon',
        //     estimation: '8/10',
        //     reviews: ''
        // },
        // {
        //     id: '24',
        //     title: 'ByBit',
        //     name: '',
        //     estimation: '10/10',
        //     reviews: ''
        // },
        // {
        //     id: '25',
        //     title: 'Kraken',
        //     name: 'Anon',
        //     estimation: '10/10',
        //     reviews: ''
        // },
        // {
        //     id: '26',
        //     title: 'OKX',
        //     name: 'Дмитрий В',
        //     estimation: '10/10',
        //     reviews: ''
        // },
        // {
        //     id: '27',
        //     title: 'Binance',
        //     name: '',
        //     estimation: '10/10',
        //     reviews: ''
        // },
        // {
        //     id: '28',
        //     title: 'Kraken',
        //     name: 'anon',
        //     estimation: '7/10',
        //     reviews: ''
        // },
        // {
        //     id: '29',
        //     title: '',
        //     name: '',
        //     estimation: '7/10',
        //     reviews: ''
        // },
        // {
        //     id: '30',
        //     title: '',
        //     name: '',
        //     estimation: '7/10',
        //     reviews: ''
        // }
    ];

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 3000,
    };

    return (
        <div className="exchenger-slider">
            <Slider {...settings}>
                {sliderData.map(item => (
                    <div key={item.id} className="slider-card">
                        <div className="exchenger__slider-cadr">
                            <div className="exchenger__card-user">
                                <img src={Profile} alt="profile" />
                                <p>{item.name}</p>
                            </div>
                            <div className="exchenher__cadr-line" ><span></span></div>
                            <div className="exchenger__card-title">
                                <h3>{item.title}</h3>
                                <div ><p>{item.estimation}</p></div>
                            </div>
                            <div className="exchenger__card-description">
                                <p>{item.reviews}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export { BadExchengerSlider };
