import React from "react";
import Slider from "react-slick";

import './exchangerSlider.css'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Profile from './../../../../image/profile.svg'

const ExchengerSlider = () => {
    const sliderData = [
        {
            id: '1',
            title: 'Binance',
            name: 'Anon',
            estimation: '10 / 10',
            reviews: 'Отличный обменник! Быстро и без лишних вопросов. Рекомендую!'
        },
        {
            id: '2',
            title: 'ByBit',
            name: 'Александр В',
            estimation: '10 / 10',
            reviews: 'Пользуюсь этим сервисом уже несколько месяцев. Всегда все проходит гладко.'
        },
        {
            id: '3',
            title: 'Kraken',
            name: 'Anon',
            estimation: '10 / 10',
            reviews: 'Обменял деньги за считанные минуты. Очень доволен!'
        },
        {
            id: '4',
            title: 'OKX',
            name: 'Anon',
            estimation: '7 / 10',
            reviews: 'Неплохой курс, но иногда бывают задержки с переводом'
        },
        {
            id: '5',
            title: 'Binance',
            name: 'Кирилл Б',
            estimation: '10 / 10',
            reviews: 'Служба поддержки на высоте! Ответили на все вопросы быстро'
        },
        {
            id: '6',
            title: 'ByBit',
            name: 'Максим К',
            estimation: '10 / 10',
            reviews: 'Пробовал много обменников, но этот — лучший. Рекомендую всем'
        },
        {
            id: '7',
            title: 'Binance',
            name: 'Илья К',
            estimation: '10 / 10',
            reviews: 'Интерфейс удобный, все понятно даже для новичка.'
        },
        {
            id: '8',
            title: 'Binance',
            name: 'Anon',
            estimation: '8 / 10',
            reviews: 'Не всегда самый выгодный курс, но надежность на первом месте.'
        },
        {
            id: '9',
            title: 'ByBit',
            name: 'Anon',
            estimation: '10 / 10',
            reviews: 'Обменивал небольшую сумму — все прошло отлично!'
        },
        {
            id: '10',
            title: 'Coinbase Exchange',
            name: 'Anon',
            estimation: '6 /10',
            reviews: 'К сожалению, столкнулся с проблемами при выводе средств. Но поддержка помогла.'
        },
        {
            id: '11',
            title: 'OKX',
            name: 'Борис С',
            estimation: '10 / 10',
            reviews: 'Быстро, удобно и безопасно. Я доволен!'
        },
        {
            id: '12',
            title: 'Binance',
            name: 'Anon',
            estimation: '10 / 10',
            reviews: 'Часто делаю обмены здесь, и ни разу не было проблем.'
        },
        {
            id: '13',
            title: 'ByBit',
            name: 'Anon',
            estimation: '9 / 10',
            reviews: 'Курс меняется слишком часто, но в целом все устраивает.'
        },
        {
            id: '14',
            title: 'MEXC',
            name: 'Виктория Д',
            estimation: '10 / 10',
            reviews: 'Рекомендую, если вам важна скорость обмена!'
        },
        {
            id: '15',
            title: 'P2B',
            name: 'Anon',
            estimation: '4 / 10',
            reviews: 'Не самый лучший опыт — были задержки с переводом.'
        },
        {
            id: '16',
            title: 'Binance',
            name: 'Anon',
            estimation: '10 / 10',
            reviews: 'Все сделано профессионально, без каких-либо сложностей.'
        },
        {
            id: '17',
            title: 'HashKey Global',
            name: 'Роман Ш',
            estimation: '10 / 10',
            reviews: 'Отличный сервис для начинающих! Все просто и понятно.'
        },
        // {
        //     id: '18',
        //     title: 'Kraken',
        //     name: 'Anon',
        //     estimation: '10/10',
        //     reviews: ''
        // },
        // {
        //     id: '19',
        //     title: 'OKX',
        //     name: '',
        //     estimation: '10/10',
        //     reviews: ''
        // },
        // {
        //     id: '20',
        //     title: 'Binance',
        //     name: '',
        //     estimation: '10/10',
        //     reviews: ''
        // },
        // {
        //     id: '21',
        //     title: 'Kraken',
        //     name: 'anon',
        //     estimation: '7/10',
        //     reviews: ''
        // },
        // {
        //     id: '22',
        //     title: '',
        //     name: '',
        //     estimation: '7/10',
        //     reviews: ''
        // },
        // {
        //     id: '23',
        //     title: 'Binance',
        //     name: 'Anon',
        //     estimation: '8/10',
        //     reviews: ''
        // },
        // {
        //     id: '24',
        //     title: 'ByBit',
        //     name: '',
        //     estimation: '10/10',
        //     reviews: ''
        // },
        // {
        //     id: '25',
        //     title: 'Kraken',
        //     name: 'Anon',
        //     estimation: '10/10',
        //     reviews: ''
        // },
        // {
        //     id: '26',
        //     title: 'OKX',
        //     name: 'Дмитрий В',
        //     estimation: '10/10',
        //     reviews: ''
        // },
        // {
        //     id: '27',
        //     title: 'Binance',
        //     name: '',
        //     estimation: '10/10',
        //     reviews: ''
        // },
        // {
        //     id: '28',
        //     title: 'Kraken',
        //     name: 'anon',
        //     estimation: '7/10',
        //     reviews: ''
        // },
        // {
        //     id: '29',
        //     title: '',
        //     name: '',
        //     estimation: '7/10',
        //     reviews: ''
        // },
        // {
        //     id: '30',
        //     title: '',
        //     name: '',
        //     estimation: '7/10',
        //     reviews: ''
        // }
    ];

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 3000,
    };

    return (
        <div className="exchenger-slider">
            <Slider {...settings}>
                {sliderData.map(item => (
                        <div key={item.id} className="slider-card">
                                <div className="exchenger__slider-cadr">
                                    <div className="exchenger__card-user">
                                        <img className="exchenger__card-photo" src={Profile} alt="profile" />
                                        <p>{item.name}</p>
                                    </div>
                                    <div className="exchenher__cadr-line" ><span></span></div>
                                    <div className="exchenger__card-title">
                                        <h3>{item.title}</h3>
                                        <div ><p>{item.estimation}</p></div>
                                    </div>
                                    <div className="exchenger__card-description">
                                        <p>{item.reviews}</p>
                                    </div>
                                </div>
                        </div>
                ))}
            </Slider>
        </div>
    );
};

export { ExchengerSlider };
