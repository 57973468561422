import ExchangeList from './component/exchangeList/ExchangeList';
import { Wallet } from './component/wallet/Wallets';

import './exchanger.css'

import { ValueCoin } from './component/valueCoin/ValueCoin';
import { ExchengersForm } from './component/exchangerForm/ExchengerForm';
import { ExchengerSlider } from './component/slider/ExchengerSlider';
import { BadHeader } from './badExchanger/BadHeader/BadHeader';
import { ExchangerDescr } from './component/exchangerDescr/ExchangerDescr';

function Exchanger (){
    return (
        <div className="exchanger__wrapper">
            <BadHeader />
            <div className="container">
                <div className="exchanger__items">
                    <aside className='exchanger__aside'>
                        <div className="exchanger__wallet">
                            <Wallet />
                        </div>
                        <div className="exchanger__coin">
                            <ValueCoin />
                        </div>
                    </aside>
                    <section className='exchanger__section'>
                        <div className="exchenger-item">
                            <ExchangerDescr />
                            <ExchangeList />
                        </div>
                    </section>
                </div>
                <div className="form_footer">
                    <ExchengerSlider />
                    <ExchengersForm />
                </div>
             </div>
            
        </div>
    )
}

export {Exchanger};