import './ExchangerDescr.css'

function ExchangerDescr (){
    return(
        <div className="description__inner">
            <p className="description__item"><span>Белый список</span> - это список благоприятных обменников криптовалюты, который составлялся  экспертами компании Криптоевразия. Проводя различные действия в обменниках из этого списка, вы можете быть уверены, что с вашими средствами ничего не случится, и все операции будут выполнены корректно</p>
        </div>
    )
}

export {ExchangerDescr};