
import logo from './../../../../image/logo.svg';
import './../../../header/header.css'


function BadHeader () {
    return(
        <header className="header">
            <div className="container">
                <div className="header__inner">
                    <div className="header__navigation">
                        <div className="header__logo">
                            <a href="/">
                                <img src={logo} alt="LOGO" />
                            </a>
                        </div>
                        <nav className="header__nav">
                            <ul className="header__nav-list">
                                <li className="header__nav-items"><a href="/exchanger">Белый список </a></li>
                                <li className="header__nav-items"><a href="/badExchenger">Черный список</a></li>
                                <li className="header__nav-items"><a href="#badForm">Оставить отзыв</a></li>
                            </ul>
                        </nav>
                    </div>
                    <div className="header__conaction">
                        <div className="header__tg">
                            <a href="https:\\t.me/CryptoEurasiaDecdfund" target='_blank' rel="noopener noreferrer">@CryptoEurasiaDecdfund</a>
                        </div>
                        <div className="header__btn">
                            <a href="#badForm">Оставить отзыв</a>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export {BadHeader};