import React from "react";
import {BlogCards } from "./blogCard/BlogCards";
import './blogCard/blogCards.css'

class Blogs extends React.Component{

    state ={
        blogs:[
            {
                id: 'blog1',
                name: 'Будущее криптовалют',
                description: 'Будущее криптовалют остаётся предметом активных дискуссий среди экспертов и инвесторов.Многие компании начинают принимать криптовалюты как способ оплаты, а также внедряют технологии блокчейн для повышения прозрачности и безопасности своих операций.'
            },
            {
                id: 'blog2',
                name: 'Криптовалюта: Основы и Текущая Реальность',
                description: 'Криптовалюта — это цифровые или виртуальные деньги, использующие криптографию для обеспечения безопасности. Основная идея заключается в децентрализации. Криптовалюты не контролируются государственными учреждениями или центральными банками.'
            }
        ]
    }

    render(){
        return <section className="blog">
            <BlogCards blogs={this.state.blogs} />
        </section>
    }
}

export {Blogs};