// import { Accordion } from "./accordion/Accordion";

// function Faq(){
//     const faqList = [
//         {
//             question: '',
//             answer: 'Криптоевразия сотрудничает с государственными органами по части комплексной безопасности, предупреждение рисков, связанных с сотрудниками и контрагентами',
//         },
//         {
//             question: '?',
//             answer: 'Криптоевразия сотрудничает с государственными органами по части комплексной безопасности, предупреждение рисков, связанных с сотрудниками и контрагентами',
//         },
//         {
//             question: '',
//             answer: 'Криптоевразия сотрудничает с государственными органами по части комплексной безопасности, предупреждение рисков, связанных с сотрудниками и контрагентами',
//         },
//         {
//             question: 'Как работают список Черных и проверенных обменников?',
//             answer: 'Криптоевразия сотрудничает с государственными органами по части комплексной безопасности, предупреждение рисков, связанных с сотрудниками и контрагентами',
//         },
//     ]

//     return <Accordion faqLsit={faqList} />
// }

// export {Faq};

import Accordion from './accordion/Accordion'

// Пример использования компонента
const items = [
    {
        title: "Как происходит работа над задачей?",
        content: "Получив запрос, наша команда связывается с вами и узнает все детали. Проведя расследование, мы возвращаемся к вам с конкретным планом решения задачи, Заключаем договор и производим намеченный план работ"
    },
    {
        title: "Кто может воспользоваться услугами Криптоевразии?",
        content: "Услугами нашей компании могут воспользоваться как жертвы мошенников и грабителей, так и физические лица, инвесторы и бизнес."
    },
    {
        title: "Как Криптоевразия сотрудничает с государственными органами?",
        content: "Криптоевразия сотрудничает с государственными органами по части комплексной безопасности, предупреждение рисков, связанных с сотрудниками и контрагентами"
    },
    {
        title: "Как работают список Черных и проверенных обменников?",
        content: "And this is the content of the third item."
    }
];

const Faq = () => (
    <section className='accordion'>

        <Accordion items={items} />
    </section>
);

export {Faq};
